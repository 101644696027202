<template>
  <div id="home" class="home">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    const htmlContent = ref("");
    const loadHtml = () => {
      axios
        .get("https://rtpterbaru2023.com/assets/homepage.html")
        .then((response) => {
          htmlContent.value = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const loadCss = () => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href =
        "https://cdn.susu-na-khap.com/rtpterbaru2023%2Fassets%2Fcustom_style.css";
      document.head.appendChild(link);
    };

    onMounted(() => {
      loadHtml();
      loadCss();
    });

    return { htmlContent };
  },
};
</script>

<style>
.home {
  padding-top: 30px;
}
</style>
